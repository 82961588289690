.contato-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 60px 20px;
  min-height: calc(100vh - 120px);
  color: white;
  margin: 50px 0;
}

.contato-container h1 {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.67);
  padding: 5px 10px;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

input,
textarea {
  padding: 15px;
  font-size: 1.2rem;
  border-radius: 10px;
  border: none;
  margin-bottom: 20px;
}

textarea {
  min-height: 150px;
}

button {
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 10px;
  background-color: #1d8aff;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

p {
  margin-top: 20px;
  font-size: 1.2rem;
}

/* Estilos do Pop-up */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

.popup-content {
  background-color: #333;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.popup-content p {
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: white;
}

.popup-content button {
  padding: 10px 20px;
  font-size: 1.2rem;
  border: none;
  border-radius: 10px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.popup-content button:hover {
  background-color: #0056b3;
}

/* Estilos responsivos */
@media (max-width: 768px) {

  .contato-container h1 {
    font-size: 2rem;
  }

  input,
  textarea {
    font-size: 1rem;
  }

  button {
    font-size: 1rem;
  }

  p {
    font-size: 1rem;
  }

  .popup-content p,
  .popup-content button {
    font-size: 1rem;
  }

}

@media (max-width: 480px) {

  .contato-container h1 {
    font-size: 1.55rem;
  }

  input,
  textarea {
    font-size: 0.9rem;
  }

  button {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.9rem;
  }

  .popup-content p,
  .popup-content button {
    font-size: 0.9rem;
  }
  
}
