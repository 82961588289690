.sobre-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 60px 20px;
  min-height: calc(100vh - 120px);
  color: white;
}

.sobre-content {
  max-width: 800px;
  width: 100%;
}

.sobre-video {
  width: 100%;
  max-width: 100%;
  margin: 20px 0;
  border-radius: 10px;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.text-container {
  background-color: rgba(0, 0, 0, 0.796);
  padding: 20px;
  border-radius: 10px;
  margin: 10px 0;
}

.text-container p {
  font-size: 1rem;
  margin: 10px 0;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1rem;
  }

  .text-container {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  h1 {
    font-size: 1.5rem;
  }

  p {
    font-size: 0.9rem;
  }

  .text-container {
    padding: 10px;
  }
}
