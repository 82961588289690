.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: rgba(0, 0, 0, 0.826);
  color: white;
  position: fixed;
  width: 97.5%;
  top: 0;
  z-index: 1000;
}

.navbar-title {
  margin: 0;
  font-size: 45px;
  font-family: "Libre Barcode 128 Text", system-ui;
  font-style: normal;
}

.navbar-title a {
  color: white;
}

.navbar-links {
  display: flex;
  list-style: none;
  transition: transform 0.3s ease-in-out;
  margin-right: 20px;
}

.navbar-links li {
  margin-left: 20px;
}

.navbar-links li a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.navbar-links li a:hover {
  color: rgb(60, 60, 255);
}

.hamburger {
  margin-right: 25px;
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px;
  transition: 0.4s;
}

@media (max-width: 768px) {
  .navbar-links {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 49px;
    right: 0;
    width: 200px;
    background-color: rgba(0, 0, 0, 0.826);
    transform: translateX(100%);
    margin-right: 0;
  }

  .navbar-links.open {
    transform: translateX(0);
  }

  .navbar-links li {
    margin: 10px 0;
    text-align: center;
  }

  .hamburger {
    display: flex;
  }

  .hamburger .line.open:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .hamburger .line.open:nth-child(2) {
    opacity: 0;
  }

  .hamburger .line.open:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
}