.chat-container {
  display: flex;
  flex-direction: column;
  height: 75vh;
  padding: 10px;
  margin-top: 80px;
  margin-bottom: 70px;
  margin-left: 15px;
  margin-right: 15px;
  background: rgba(0, 0, 0, 0.8);
  color: white;
}

.messages {
  flex: 1;
  overflow-y: auto;
  margin-bottom: 30px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.message {
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.395);
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.input-container input {
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
}

.input-container button {
  padding: 10px;
  margin-bottom: 20px;
  border: none;
  border-radius: 5px;
  background: blue;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

@media (min-width: 600px) {

  .chat-container {
    margin-top: 90px;
    margin-bottom: 15px;
    margin-left: 25px;
    margin-right: 25px;
    height: 80vh;
  }

  .input-container {
    flex-direction: row;
    align-items: center;
  }

  .input-container input {
    flex: 1;
  }

  .input-container button {
    width: 100px;
  }
  
}
