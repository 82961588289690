.footer {
  background-color: rgb(0, 0, 0);
  color: white;
  text-align: center;
  padding: 0;
  width: 100%;
  margin-top: auto;
}

.social-media {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 5px;
}

.social-media a {
  font-size: 22px;
  color: white;
  text-decoration: none;
}

.social-media a:hover {
  color: blue;
}

.devPor {
  font-size: 12px;
  margin: 5px 5px;
}

.text-midias {
  font-size: 22px;
}