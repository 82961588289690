.carousel-container {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
  margin-top: 100px;
  margin-bottom: 30px;
}

.carousel {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel-image {
  width: 100%;
  max-height: 500px;
  object-fit: cover;
  border-radius: 8px;
}

.carousel-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  z-index: 1;
}

.carousel-control.prev {
  left: 10px;
}

.carousel-control.next {
  right: 10px;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .carousel-container {
    margin-top: 30%;
    margin-bottom: 50%;
    max-width: 100%;
  }

  .carousel-control {
    padding: 5px;
    font-size: 14px;
  }

  .carousel-image {
    max-height: 300px;
  }
}