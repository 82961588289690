.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: calc(100vh - 60px);
  padding: 20px;
  padding-top: 60px;
  color: white;
}

.home-content {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 10px;
}

.home-content h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.home-content p {
  font-size: 1.5rem;
  margin: 0;
}

/* Estilos responsivos */
@media (max-width: 768px) {

  .home-content h1 {
    font-size: 2rem;
  }

  .home-content p {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {

  .home-content h1 {
    font-size: 1.5rem;
  }

  .home-content p {
    font-size: 1rem;
  }
}
