body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.App {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-image: url('../src/assets/background.png');
  background-size: cover;
  background-position: center;
}

footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 10px 0;
  width: 100%;
  margin-top: auto;
}