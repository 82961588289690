.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: white;
    background-color: rgb(18, 18, 18);
  }
  
  .not-found h1 {
    font-size: 7em;
    margin: 0;
  }
  
  .not-found p {
    font-size: 2em;
    font-family: "Dancing Script", cursive;
    text-decoration: underline;
  }
  